var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"login-box"},[_vm._m(0),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","placeholder":"电子邮件/电话/用户号码"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}})]),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"action-row"},[_vm._m(4),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"next-btn",on:{"click":_vm.login}},[_vm._v("下一步")])])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../../src/assets/google.png"),"alt":"Google Logo"}})]),_c('h2',{staticClass:"login-text"},[_vm._v("登录")]),_c('h2',{staticClass:"gmail-text"},[_vm._v("继续使用Gmail")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password"},[_c('a',{attrs:{"href":"#"}},[_vm._v("忘记了电子邮件地址?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-passwordxx"},[_c('a',[_vm._v("不是您自己的计算机？请使用访客模式无痕登录。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-passwordaa"},[_c('a',{attrs:{"href":"https://support.google.com/chrome/answer/6130773?hl=zh-CN","jsname":"JFyozc","target":"_blank"}},[_vm._v("详细了解如何使用访客模式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-usname"},[_c('a',[_vm._v("创建账号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"footer-content"},[_c('a',{staticClass:"footer-link",attrs:{"href":"#"}},[_vm._v("简体中文")]),_c('a',{staticClass:"footer-link",attrs:{"href":"#"}},[_vm._v("帮助")]),_c('a',{staticClass:"footer-link",attrs:{"href":"#"}},[_vm._v("隐私权")]),_c('a',{staticClass:"footer-link",attrs:{"href":"#"}},[_vm._v("条款")])])])
}]

export { render, staticRenderFns }