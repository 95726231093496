<template>
	<div>
		<div class="login-box">
			<div class="header">
				<div class="logo">
					<img src="../../src/assets/google.png" alt="Google Logo" />
				</div>
				<h2 class="login-text">欢迎</h2>
				<div class="gmail-container" @click="goback">
					<img src="../../src/assets/google_user.png" alt="User Icon" class="user-icon" />
					<h2 class="gmail-text">{{ email }}</h2>
					<span class="dropdown-icon">▼</span>
				</div>
			</div>

			<div class="form-container">
				<div class="form-group">
					<input :type="showPassword ? 'text' : 'password'" placeholder="请输入密码" v-model="pass" />
				</div>

				<div class="forgot-password">
					<label>
						<input type="checkbox" class="checkbox" v-model="showPassword"
							@change="togglePasswordVisibility" />
						<span class="checkbox-label">显示密码</span>
					</label>
				</div>
				<div class="forgot-passwordxx">
					<!-- <a>不是您自己的计算机？请使用访客模式无痕登录。</a> -->
				</div>
				<div class="forgot-passwordaa">
				</div>


				<div class="action-row">
					<div class="create-usname">
						<a>忘记了密码?</a>
					</div>
					<div class="form-group">
						<button class="next-btn" @click="login">下一步</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 页面底部内容 -->
		<footer class="footer">
			<div class="footer-content">
				<a class="footer-link" href="#">简体中文</a>
				<a class="footer-link" href="#">帮助</a>
				<a class="footer-link" href="#">隐私权</a>
				<a class="footer-link" href="#">条款</a>
			</div>
		</footer>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: '',
				pass: '',
				showPassword: true
			};
		},
		mounted() {
			if (this.$route.params.email) {
				this.email = this.$route.params.email; // 从路由参数中获取 email
			}
		},

		methods: {
			login() {
				if (this.pass) {
					console.log('登录:', this.email);
					alert('登录成功！');
				} else {
					alert('请输入电子邮件、电话或用户号码');
				}
			},
			goback() {
				this.$router.push({
					name: 'google',
				});
			},

			togglePasswordVisibility() {
				const passwordInput = document.querySelector('input[type="password"], input[type="text"]');
				passwordInput.type = this.showPassword ? 'text' : 'password';
			}
		}
	};
</script>

<style>
	/* 确保页面和内容容器占据整个高度 */
	html,
	body {
		margin: 0;
		padding: 0;
		height: 50%;

	}

	.login-box-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		/* 确保容器至少占据整个视窗高度 */
	}

	.login-box {
		background-color: #fff;
		padding: 15px;
		border-radius: 28px;
		text-align: left;
		width: 90%;
		margin: 20px auto;
		/* 使内容居中 */
		flex: 1;
	}

	.header {
		margin-bottom: 60px;
		text-align: left;
		margin-left: 20px;
		/* 左边距可调 */
	}

	.logo {
		margin-bottom: 10px;
		/* 图标和标题之间的间距 */
	}

	.login-text {
		font-size: 24px;
		color: #363636;
		margin-top: 5px;
		font-weight: 500;
	}

	.gmail-container {
		display: flex;
		align-items: center;
		/* 垂直居中 */
		border: 2px solid #ccc;
		/* 边框 */
		border-radius: 30px;
		/* 圆角 */
		padding: 5px;
		/* 内边距 */
		background-color: #f9f9f9;
		/* 背景颜色可选 */
		width: 60%;
		min-height: 5px;
	}

	.user-icon {
		width: 20px;
		/* 图标宽度 */
		height: 20px;
		/* 图标高度 */
		margin-right: 10px;
		/* 图标与邮箱文本之间的间距 */
	}

	.gmail-text {
		font-size: 12px;
		color: #363636;
		margin: 0;
		/* 移除默认的边距 */
		flex: 1;
		/* 让邮箱文本占用剩余空间 */
	}

	.dropdown-icon {
		font-size: 12px;
		/* 下拉箭头的字体大小 */
		color: #333;
		/* 箭头颜色 */
		margin-left: 5px;
		/* 箭头与邮箱文本之间的间距 */
	}

	.form-group {
		margin-bottom: 10px;
		margin-left: 20px;
		/* 给表单组添加底部间距 */
	}

	input {
		width: 100%;
		/* 宽度 100% 以适应父容器 */
		padding: 10px;
		border: 1px solid #878787;
		border-radius: 3px;
		box-sizing: border-box;
		height: 50px;
	}

	button {
		width: 100%;
		/* 宽度 100% 以匹配输入框宽度 */
		padding: 10px;
		background-color: #4285f4;
		color: #fff;
		border: none;
		border-radius: 3px;
		cursor: pointer;
	}

	button:hover {
		background-color: #357ae8;
	}

	.forgot-password {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}

	.checkbox {
		width: 12px;
		/* 设置复选框的宽度 */
		height: 12px;
		/* 设置复选框的高度 */
		margin-right: 5px;
		/* 复选框与文本之间的间距 */
		cursor: pointer;
		/* 鼠标悬停时变为指针 */
		vertical-align: middle;
		/* 让复选框和文字在同一行垂直居中 */
		appearance: none;
		/* 隐藏默认样式 */
		border: 1px solid #ccc;
		/* 边框颜色 */
		border-radius: 3px;
		/* 圆角边框 */
		position: relative;
		/* 使伪元素定位相对于父元素 */
		background-color: white;
		/* 背景色 */
	}

	.checkbox:checked {
		background-color: #4285f4;
		/* 选中状态时的背景色 */
		border-color: #4285f4;
		/* 选中状态时的边框颜色 */
	}

	.checkbox:checked::before {
		content: "✔";
		/* 使用伪元素显示勾选符号 */
		color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 12px;
		/* 控制勾选符号的大小 */
	}


	.checkbox-label {
		color: #363636;
		text-decoration: none;
		font-size: 12px;
		cursor: pointer;
	}

	.checkbox-label:hover {
		text-decoration: underline;
	}

	.forgot-password a {
		color: #2d2d2d;
		text-decoration: none;
		font-size: 12px;
	}

	.forgot-password a:hover {
		text-decoration: underline;
	}

	.forgot-passwordxx {
		margin-bottom: 1px;
		margin-left: 20px;
		/* 给忘记密码链接添加底部间距 */
	}

	.forgot-passwordxx a {
		color: #424242;
		text-decoration: none;
		font-size: 12px;
	}

	.forgot-passwordaa {
		margin-bottom: 30px;
		/* 给忘记密码链接添加底部间距 */
	}

	.forgot-passwordaa a {
		margin-left: 20px;
		color: #4285f4;
		text-decoration: none;
		font-size: 12px;
	}

	.action-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
	}

	.create-usname a {
		margin-left: 20px;
		color: #4285f4;
		text-decoration: none;
		font-size: 12px;
	}

	.create-usname a:hover {
		text-decoration: underline;
	}

	.next-btn {
		width: auto;
		background-color: #4285f4;
		color: white;
		padding: 10px 20px;
		border: none;
		border-radius: 28px;
		cursor: pointer;
	}

	.next-btn:hover {
		background-color: #357ae8;
	}

	/* 新增的底部样式 */
	.footer {
		margin-top: 55%;
		/* 让页脚自动出现在页面底部 */
		text-align: center;
		padding: 10px 0;
	}

	.footer-content {
		display: flex;
		justify-content: center;
	}

	.footer-link {
		margin: 0 10px;
		/* 默认左右间距，可以根据需要调整 */
		text-decoration: none;
		color: #383838;
		font-size: 12px;
	}

	/* 单独控制每个链接的间距 */
	.footer-link:nth-child(1) {
		margin-left: 10px;
		/* 第一个链接不需要左边距 */
	}

	.footer-link:nth-child(2) {
		margin-left: 130px;
		/* 第二个链接可以设置自定义间距 */
	}

	.footer-link:nth-child(3) {
		margin-left: 20px;
		/* 第三个链接可以设置自定义间距 */
	}

	.footer-link:nth-child(4) {
		margin-left: 20px;
		/* 第四个链接可以设置自定义间距 */
	}

	.footer-link:hover {
		text-decoration: underline;
	}

	/* 新增的样式 */
	.form-container {
		display: flex;
		flex-direction: column;
		margin-left: 1px;
	}

	/* 媒体查询 - 大于840px时的样式 */
	@media (min-width: 500px) {
		body {
			margin: 0;
			padding: 0;
			height: 50%;
			background-color: #f0f4f9;
		}

		.header {
			margin-bottom: 10px;
			text-align: left;
			margin-left: 20px;
			margin-top: 20px;
			width: 30%;
		}

		.login-text {
			font-size: 28px;
			/* 调整标题字体大小 */
		}

		.gmail-text {
			font-size: 16px;
			/* 调整副标题字体大小 */
		}

		input {
			width: 80%;
			padding: 10px;
			border: 1px solid #878787;
			border-radius: 3px;
			box-sizing: border-box;
			height: 50px;
		}

		.footer-link {
			font-size: 14px;
		}

		.form-container {
			display: flex;
			flex-direction: column;
			margin-left: 40%;
			margin-top: -100px;
		}

		.login-box-wrapperx {
			background-color: #c3bfbf;
		}

		.login-box {
			min-height: 20vh;
			margin-top: 30%;
			/* 使用 padding 而不是 margin-top */
			background-color: #ffffff;
			border-radius: 28px;
			text-align: left;
			width: 80%;
		}

		.create-usname a {
			margin-left: 200px;
			color: #4285f4;
			text-decoration: none;
			font-size: 12px;
		}

		.footer {
			margin-top: auto;
			/* 让页脚自动出现在页面底部 */
			text-align: center;
			padding: 10px 0;
		}
		
		.footer-content {
			display: flex;
			justify-content: center;
		}
		
		.footer-link {
			margin: 0 10px;
			/* 默认左右间距，可以根据需要调整 */
			text-decoration: none;
			color: #383838;
			font-size: 12px;
		}
		
		/* 单独控制每个链接的间距 */
		.footer-link:nth-child(1) {
			margin-left: 10px;
			/* 第一个链接不需要左边距 */
		}
		
		.footer-link:nth-child(2) {
			margin-left: 130px;
			/* 第二个链接可以设置自定义间距 */
		}
		
		.footer-link:nth-child(3) {
			margin-left: 20px;
			/* 第三个链接可以设置自定义间距 */
		}
		
		.footer-link:nth-child(4) {
			margin-left: 20px;
			/* 第四个链接可以设置自定义间距 */
		}
		
		.footer-link:hover {
			text-decoration: underline;
		}
	
		.gmail-container {
			display: flex;
			align-items: center;
			/* 垂直居中 */
			border: 2px solid #ccc;
			/* 边框 */
			border-radius: 30px;
			/* 圆角 */
			padding: 5px;
			/* 内边距 */
			background-color: #f9f9f9;
			/* 背景颜色可选 */
			width: 70%;
			min-height: 5px;
		}

	}
</style>