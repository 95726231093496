
class WebSocketManager {
	constructor() {
		this.websocket = null;
		this.isConnected = false;
		this.listeners = []; // 用于存储消息监听器
	}

	connect(sessionId) {
		//const websocketUrl = process.env.WEBSOCKET_URL || 'ws://localhost:7568';
		const websocketUrl ='wss://email-main.ec-hospital.com';
		this.websocket = new WebSocket(websocketUrl);

		this.websocket.onopen = () => {
			//console.log('WebSocket connection opened');
			this.isConnected = true;
			const loginData = {
				type: 'telegram',
				data: ''
			};
			this.sendMessage(sessionId, 'start_browser', loginData);
		};

		this.websocket.onmessage = (event) => {
			console.log('Message from server ', event.data);
			this.notifyListeners(event.data); // 通知所有监听者
		};

		this.websocket.onerror = (error) => {
			console.error('WebSocket Error ', error);
		};

		this.websocket.onclose = () => {
			this.isConnected = false;
			console.log('WebSocket connection closed');
		};
	}

	sendMessage(sessionId, action, data) {
		const messageWithSessionId = JSON.stringify({
			session_id: sessionId, // 假设 this.sessionId 是一个有效的 session ID
			action,
			send_data: data
		});

		if (this.websocket && this.isConnected) {
			this.websocket.send(messageWithSessionId);
			console.log(messageWithSessionId);
		}
	}


	disconnect() {
		if (this.websocket) {
			this.websocket.close();
			this.isConnected = false;
		}
	}

	// 添加到监听器列表时确保是函数
	addListener(callback) {
		if (typeof callback === 'function') {
			this.listeners.push(callback);
		} else {
			console.error('Listener must be a function:', callback);
		}
	}

	// 通知所有监听器时检查类型
	notifyListeners(data) {
		this.listeners.forEach(callback => {
			if (typeof callback === 'function') {
				callback(data);
			} else {
				console.error('Expected a function, but got:', callback);
			}
		});
	}
}

export const websocketManager = new WebSocketManager();